<!-- 卷轴式幸运机 -->
<style scoped>
    .column-move{
        transition:transform cubic-bezier(0.665, -0.065, 0.325, 1.065);
    }
    .bg{
        background: url('@/assets/images/room/tiger/god_bg.jpg') no-repeat top right/cover;
    }
    .max-h-85{
        max-height: 85%;
    }
    .max-w-90{
        max-width: 90%;
    }
    .led-circle{
        margin:-2rem;
        background:
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 0 0 / 9.12% 5rem repeat-x,/*上*/
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 0 0 / 9.12% 12.5% repeat-y,/*左*/
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 0 100% / 9.12% 5rem repeat-x,/*下*/
            radial-gradient(circle, rgb(255, 255, 255) .3rem, rgb(72, 229, 255) .5rem, rgb(72, 229, 255) .55rem, rgb(0, 186, 217) .6rem, rgba(0, 206, 255, 0.39) .75rem, transparent 1.75rem) 100% 0 / 8.62% 12.5% repeat-y/*右*/
            /* linear-gradient(133deg,transparent 0%,rgba(255,255,255,0.3) 30%,transparent 40%) 2rem 2rem no-repeat */
    }
    /* linear-gradient(0deg,rgb(0, 109, 255),rgb(0, 186, 217)), */
    .control-container{
        background: radial-gradient(circle at top,transparent 3.4rem,rgb(0, 109, 255) 3.5rem);
        border-top-left-radius: 4rem 4rem;
        border-top-right-radius: 4rem 4rem;
    }
    .wander:hover{
        animation-play-state: paused;
    }
    .gold{
        box-shadow: 0 0 0 .6rem #fff27b,.05rem -.05rem 0 .6rem #fffede,-.05rem .05rem 0 .05rem #fffede inset, 0 .05rem 0 .15rem #efb952 inset;
        background: #ffd93b;
        color: #fff17a;
        text-shadow: .1rem -.1rem #efaf1c;
    }
    .wander{
        margin-top: -2rem;
        margin-bottom: 1rem;
        animation: wander 2s ease-in-out infinite alternate;
    }
    @keyframes wander {
        to{
            margin-top:-2.5rem;
            margin-bottom:1.5rem;
        }
    }
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <!-- 内容 -->
        <div class="d-flex flex-column flex-grow-1 position-relative justify-content-center bg">
            <!-- 卷轴区 -->
            <div class="d-flex flex-grow-1">
                <div class="w-60 position-relative" style="margin-left:10%">
                    <div class="d-flex position-absolute top-50 translate-middle-y h-0 w-100 rounded-10 bg-light" style="padding-top:65%;box-shadow: rgb(0, 17, 255) 0 0 .5rem .05rem, rgb(0 109 255) 0 0 .3rem .4rem inset;">
                        <div class="position-absolute top-0 bottom-0 end-0 start-0 d-flex m-3 rounded-5" style="background:#04083d;box-shadow: rgb(71, 148, 251) -.1rem .1rem .4rem .6rem;">
                            <div class="d-flex flex-grow-1 rounded-5" style="box-shadow: rgb(0, 17, 255) 0 0 .25rem .05rem inset, rgb(255, 255, 255) 0 0 .3rem .1rem;">
                                <div class="col d-flex position-relative overflow-hidden">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" style="transition-duration:1.5s" :style="columnMoveStyle">
                                        <div v-for="(val,key) in imgList[0]" :key="(val,key)" class="position-relative flex-shrink-0 h-33">
                                            <img class="position-absolute start-50 top-50 translate-middle max-w-90 max-h-85" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative overflow-hidden">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" style="transition-duration:2s" :style="columnMoveStyle">
                                        <div v-for="(val,key) in imgList[0]" :key="(val,key)" class="position-relative flex-shrink-0 h-33">
                                            <img class="position-absolute start-50 top-50 translate-middle max-w-90 max-h-85" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative overflow-hidden">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" style="transition-duration:2.5s" :style="columnMoveStyle">
                                        <div v-for="(val,key) in imgList[0]" :key="(val,key)" class="position-relative flex-shrink-0 h-33">
                                            <img class="position-absolute start-50 top-50 translate-middle max-w-90 max-h-85" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative overflow-hidden">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" style="transition-duration:3s" :style="columnMoveStyle">
                                        <div v-for="(val,key) in imgList[0]" :key="(val,key)" class="position-relative flex-shrink-0 h-33">
                                            <img class="position-absolute start-50 top-50 translate-middle max-w-90 max-h-85" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex position-relative overflow-hidden">
                                    <div class="flex-grow-1 d-flex flex-column flex-column-reverse column-move" style="transition-duration:3.5s" :style="columnMoveStyle">
                                        <div v-for="(val,key) in imgList[0]" :key="(val,key)" class="position-relative flex-shrink-0 h-33">
                                            <img class="position-absolute start-50 top-50 translate-middle max-w-90 max-h-85" :src="require('@/assets/images/room/tiger/'+val+'.png')" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="position-absolute top-0 bottom-0 end-0 start-0 led-circle"></div>
                    </div>
                </div>
            </div>
            <!-- 操作栏 -->
            <div class="d-flex control-container justify-content-center">
                <div class="rounded-circle w-4rem h-4rem d-flex align-items-center justify-content-center wander gold fw-bold fs-5" @click="start">开始</div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script>
import navbar from '@/components/public/navbar.vue';
export default {
    components: {
        navbar
    },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            imgList:[
                ['god_1','god_2','god_3','god_4','god_5','god_6','god_7','god_8','god_9','god_10','god_11','god_12','god_1','god_2','god_3','god_4','god_5','god_6','god_7','god_8','god_9','god_10','god_11','god_12','god_1','god_2','god_3','god_4','god_5','god_6','god_7','god_8','god_9','god_10','god_11','god_12'],[],[],[],[]
            ],
            columnMoveStyle:{
                transform: ''
            },
        };
    },
    mounted() {
        
    },
    methods: {
        async start(){
            
            // for (let i = 0; i < 2 ; i++) {
            //     console.log(i)
            //     await new Promise(resolve=>{
            //         this.columnMoveStyle={ 
            //             // transition:'transform .5s linear',
            //             transform: 'translateY(33.3333%)' 
            //         } 
            //         this.$nextTick(()=>{
            //             setTimeout(async () => {
            //                 console.log("--")
            //                 this.imgList[0].push(this.imgList[0].shift());
            //                 this.columnMoveStyle={
            //                     transitionDuration:'0s',
            //                     transform: 'translateY(0%)' 
            //                 };
            //                 this.$nextTick(()=>{
            //                     resolve(); 

            //                 })
            //                     // setTimeout(() => {
            //                     // },1);
            //             }, 500);
            //         })
            //     })
            //     //console.log("结束")
                
            // }

            let end='bar';//生成一个结束位
            this.columnMoveStyle.transform="translateY(700%)";
            setTimeout(() => {
                
            }, 3500);
        }
    },
};
</script>