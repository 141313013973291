const IS_DEBUG=false;//是否开启调试模式以显示输出
const UPDATE_DATE="2024-08-23 16:31";
/**
 * websocket协议配置地址
 */
// const WS={
//     VIP1: 'ws://192.168.1.59:2171',
//     VIP2: 'ws://192.168.1.9:2030',
//     VIP3: 'ws://192.168.2.9:2020',
// }
/**
 * 使用者和对应的配置文件名称列表
 */
const USER_LIST={
    longyuan:"longyuan",
    ouya:"ouya",
}
//配置使用者，以使用对应的路由规则
const USER=USER_LIST.longyuan;
/**
 * 语言配置
 */
const LANG={
    zh:"简体中文",
    en:"English",//英语
    th:"ภาษาไทย",//泰语
    vi:"Việt",//越南语
}
/**
 * 接口
 */
const COMMAND={
    //电话
    tel_login:"tel_login",

    all_register:"all_register",
    all_login:"all_login",
    all_getuserinfo:"all_getuserinfo",
    all_getnotice:"all_getnotice",
    all_getgamelist:"all_getgamelist",
    all_getroomlist:"all_getroomlist",
    all_getroompearl:"all_getroompearl",
    all_entertable:"all_entertable",
    all_online:"all_online",
    all_getout:"all_getout",
    all_otherlogin:"all_otherlogin",
    all_logintop:"all_logintop",
    all_logout:"all_logout",
    all_startgame:"all_startgame",
    all_getline:"all_getline",
    all_getvideo:"all_getvideo",
    all_getcountdown:"all_getcountdown",
    all_changeboot:"all_changeboot",
    all_endgame:"all_endgame",
    all_cancelbet:"all_cancelbet",
    all_settlement:"all_settlement",
    all_getgain:"all_getgain",
    all_refresh:"all_refresh",
    all_getshareurl:"all_getshareurl",
    all_getbankrollway:"all_getbankrollway",
    all_getcoin:"all_getcoin",
    all_recharge:"all_recharge",
    all_withdraw:"all_withdraw",
    all_getuserchannel:"all_getuserchannel",
    all_deluserchannel:"all_deluserchannel",
    all_updatepwd:"all_updatepwd",
    all_setuserchannel:"all_setuserchannel",
    all_getrecharge:"all_getrecharge",
    all_getwithdraw:"all_getwithdraw",
    all_getbetlog:"all_getbetlog",
    all_getquestion:"all_getquestion",
    all_addquestion:"all_addquestion",
    all_getwithdrawlimit:"all_getwithdrawlimit",
    all_getchat:"all_getchat",
    all_getcurrpoker:"all_getcurrpoker",
    all_setsharepower:"all_setsharepower",
    all_roomstatechange:"all_roomstatechange",
    all_workerupdate:"all_workerupdate",
    all_readygame:"all_readygame",
    all_tableupdate:"all_tableupdate",
    all_exittable:"all_exittable",
    all_willstartgame:"all_willstartgame",
    all_gettryuser:"all_gettryuser",

    bac_gameresult:"bac_gameresult",
    bac_getodds:"bac_getodds",
    bac_getlimitred:"bac_getlimitred",
    bac_receivepool:"bac_receivepool",
    bac_submitbet:"bac_submitbet",
    bac_getbetdetail:"bac_getbetdetail",
    bac_getroompoker:"bac_getroompoker",

    cor_receivepool:"cor_receivepool",
    cor_getlimitred:"cor_getlimitred",
    cor_submitbet:"cor_submitbet",
    cor_gameresult:"cor_gameresult",
    cor_getbetdetail:"cor_getbetdetail",
    cor_getroompoker:"cor_getroompoker",
    cor_getodds:"cor_getodds",

    dice_getlimitred:"dice_getlimitred",
    dice_getbetdetail:"dice_getbetdetail",
    dice_gameresult:"dice_gameresult",

    rou_gameresult:"rou_gameresult",

    fruit_order:"fruit_order",
    fruit_log:"fruit_log",
    fruit_submitbet:"fruit_submitbet",
    fruit_getlimitred:"fruit_getlimitred",

    texa_gettablepro:"texa_gettablepro",
    texa_move:"texa_move",
    texa_submitbet:"texa_submitbet",
    texa_end:"texa_end",
    texa_getpool:"texa_getpool",
};
/**
 * 返回结果
 */
const RESULT={
    /**
     * 登录
     */
    login:{
        unPwd:-1,
        unUser:0,
        success:1,
    },
    /**
     * 进入台面
     */
    enterTable:{
        success:1,
        unmoney:3,//余额不足
    },
    /**
     * 获取试玩账号
     */
    getTryUser:{
        success:1,
        empty:-1,//暂无可用
    }
}
/**
 * 登录模式
 */
const LOGIN_MODE={
    normal:0,//正常
    quick:1,//飞机快捷登录
    try:2,//试玩登录
}
/**
 * 游戏类型
 */
const GAME_TYPE={
    all:0,//全部
    real:1,//真人
    esports:2,//电子竞技
}
/**
 * 游戏模式
 */
const GAME_MODEL={
    normal:0,//经典
    tel:1,//电话
}
/**
 * 游戏ID
 */
const GAME_ID={
    //真
    bac:1,//百
    lh:3,//龙
    cor:13,//牛
    dice:14,//骰
    rou:20,//轮
    texas:9,//德
    saga:19,//传百
    coin:25,//三公
    //电
    tiger:10,//游戏机
    corauto:6,//牛
    texasauto:8,//德
    rouauto:15,//轮
    diceauto:7,//骰
    twomj:21,//二麻
    fourmj:23,//四麻
    longfong:24,//三公
}
/**
 * 游戏名称代号
 */
const GAME_CODE={
    //真
    1:"bac",
    13:"cor",
    14:"dice",
    20:"rou",
    9:"texas",
    19:"saga",
    25:"coin",
    //电
    10:"tiger",
    6:"corauto",
    8:"texasauto",
    15:"rouauto",
    7:"diceauto",
    21:"twomj",
    23:"fourmj",
    24:"longfong",
}
/**
 * 游戏画布行数
 */
const GAME_ROW={
    4:[GAME_ID.cor,GAME_ID.dice],
    6:[GAME_ID.bac,GAME_ID.rou,GAME_ID.lh,GAME_ID.longfong,GAME_ID.coin],
}
/**
 * 颜色配置
 */
const COLOR={
    red:"#fb2401",
    blue:"#0069c5",
    green:"#00aa00",
    black:"#000000",
    ray:"#A5A5A5",//光线效果
    redDark:"#ca5a47",//暗红
    blueDark:"#78a5ce",//暗蓝
    greenDark:"#009000",//暗绿
}
/**
 * 路子类型
 */
const PEARL_TYPE={
    big_three:-3,//三星大路
    big_text:-2,//带字大路
    big_hollow:-1,//空心大路
    small_hollow:0,//空心小路，不跳列
    bevel:1,//斜线路，跳一列
    small_solid:2,//实心小路，跳两列
    result_point:3,//点数结果
}
/**
 * 弹出框类型
 */
const LAYER_TYPE={
    info:0,//提示框
    confirm:1,//确认框
    load:2,//加载层
}
/**
 * 视频模式类型
 */
const VIDEO_TYPE={
    top:0,//置顶模式
    normal:1,//正常模式
}
/**
 * 用户退出类型
 */
const LOGOUT_TYPE={
    exit:1,//注销登录
    timeout:2,//超时登出
    more:3,//多方登录
    system:4,//被后台登出
}
/**
 * 路单代号
 */
const PEARL_CODE={
    Z:['D', 'M', 'F', 'E', 'A'],
    X:['H', 'N', 'G', 'I', 'B'],
    H:['L', 'J', 'K', 'O', 'C'],
    ZD:['D', 'L', 'G', 'I', 'F', 'J'],
    XD:['H', 'L', 'I', 'F', 'K', 'E'],
    L:['A','B'],
    F:['C','D'],
    HE:['E','F'],
    LSG:['A'],
    FSG:['C'],
    HSG:['E'],
    red:[1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
    black:[2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
}
/**
 * 佣金方式
 */
const REBATE_TYPE={
    rebate:0,//非免佣
    noRebate:1//免佣
}
/**
 * 是否能免佣
 */
const IS_REBATE={
    false:0,
    true:1
}
/**
 * 提交结果
 */
const SUBMIT_RESULT={
    success:1,//成功
    ban:-2,//禁止提交
    unmoney:-5,//余额不足
    countdown:-7,//计时不足
    outside:-8,//额度和限额不匹配
    unsettlement:-9,//有未结算的提交
    service:-10,//系统维护
    malice:-11,//非法提交
}
/**
 * 充值结果
 */
const RECHARGE_RESULT={
    error:0,//充值失败
    moneyErr:-1,//金额无效
    submitMuch:-2,//提交频繁
    noVip:-3,//不是注册会员
    noUser:-4,//用户不存在
    submitAgain:-5,//重复提交
    aisleErr:-6,//充值通道异常
    bossErr:-7,//上级代理问题
    officialErr:-8,//官方充值额度不足
}
/**
 * 提现结果
 */
 const WITHDRAW_RESULT={
    noUser:-1,//用户不存在
    pwdErr:-2,//提现密码错误
    moneyLack:-3,//余额不足
    moneyErr:-4,//金额无效
    reportErr:-5,//有未结算注单
    acceptedErr:-6,//收款方不存在
    systemErr:-7,//系统维护
    error:-8,//失败
}
/**
 * 取消提交结果
 */
const CANCEL_RESULT={
    success:1,//成功
    error:0,//失败
}
/**
 * 修改密码结果
 */
const UPDATE_PWD_RESULT={
    success:1,//成功
    pwdEmpty:2,//原密码或新密码为空
    pwdErr:3,//原密码错误
    userErr:4,//用户异常
    pwdEqual:5,//新旧密码相同
}
/**
 * 资金类型(充值)
 */
const FUNDS_RECHARGE_TYPE={
    alipay:1,//支付宝
    wechat:2,//微信
    bank:3,//银联
    virtual:5,//虚拟币
}
/**
 * 资金类型
 */
const FUNDS_WITHDRAW_TYPE={
    alipay:10,//支付宝
    wechat:13,//微信
    bank:11,//银联
    virtual:12,//虚拟币
}
/**
 * 添加资金渠道结果
 */
const SET_SOURCE_RESULT={
    error:-1,//操作失败
    cannot:-2,//新增时为个数超出上限，删除时为有未完成订单
    noCurrency:-3,//无效币种
}
/**
 * 密码类型
 */
const PWD_TYPE={
    login:1,//登录
    withdraw:2//提现
}
/**
 * 皮肤类型
 */
const SKIN_TYPE={
    normal:"normal",//正常
    darkness:"darkness",//黑夜模式
}
/**
 * 充值记录充值状态
 */
const LOG_STATE={
    wait:0,//待处理
    doing:1,//处理中
    ready:8,//已完成
    fail:15,//无效
}
/**
 * 台面状态
 */
const ROOM_STATE={
    0:"正常",//正常
    1:"roomStop",//停台中
    2:"roomVideo",
    3:"roomSystem",
    4:"roomErr",
}
/**
 * store状态变更类型
 */
const MUTATION_TYPE={
    resetState:"resetState",
    setUserInfo:"setUserInfo",
    setActiveGame:"setActiveGame",
    setRoomInfo:"setRoomInfo",
    setTabGame:"setTabGame",
    setVideoLineList:"setVideoLineList",
    setCurrVideoLine:"setCurrVideoLine",
    setAudioSwitch:"setAudioSwitch",
    setGameList:"setGameList",
    setGameModel:"setGameModel",
    setSkin:"setSkin",
    setLoginModel:"setLoginModel",
    setCurrAmountList:"setCurrAmountList",
    setRoomList:"setRoomList",
    setCurrModal:"setCurrModal",
    setRechargeFundsList:"setRechargeFundsList",
    setRechargeCurrencyList:"setRechargeCurrencyList",
    setWithdrawFundsList:"setWithdrawFundsList",
    setFundChannel:"setFundChannel",
    setWithdrawLimit:"setWithdrawLimit",
    setShareQrStr:"setShareQrStr",
    setProblemList:"setProblemList",
    setRechargeLog:"setRechargeLog",
    setWithdrawLog:"setWithdrawLog",
    setSubmitLog:"setSubmitLog",

}
const AMOUTN_ARR=[10,20,50,100,200,500,1000,2000,5000,10000,20000,50000,100000,200000,500000];//全部额度列表
const RATIO = window.devicePixelRatio||1;//获取设备像素比，设备是几倍屏

export{
    COMMAND,RESULT,GAME_ID,GAME_TYPE,COLOR,
    IS_DEBUG,RATIO,PEARL_TYPE,LAYER_TYPE,LANG,
    PEARL_CODE,REBATE_TYPE,IS_REBATE,SUBMIT_RESULT,
    CANCEL_RESULT,FUNDS_RECHARGE_TYPE,FUNDS_WITHDRAW_TYPE,
    RECHARGE_RESULT,WITHDRAW_RESULT,PWD_TYPE,UPDATE_PWD_RESULT,
    SET_SOURCE_RESULT,LOG_STATE,USER_LIST,USER,GAME_ROW,VIDEO_TYPE,AMOUTN_ARR,
    GAME_CODE,ROOM_STATE,LOGIN_MODE,LOGOUT_TYPE,MUTATION_TYPE,GAME_MODEL,SKIN_TYPE,
    UPDATE_DATE
}